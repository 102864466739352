<template>
  <app-collapse accordion>
    <app-collapse-item :isVisible="true" headerStyle="background-color: #d2d6de">
      <template slot="header">
        <div class="d-flex align-items-center">
          <b-card-title class="ml-25"><feather-icon
            size="24"
            class="mr-1"
            icon="InfoIcon"
          />
            <span>Trạng Thái Lớp Học</span>
          </b-card-title>
        </div>
      </template>
      <b-row>
        <!--  Full name-->
        <b-col md="12">
          <b-form-group
            label="Trạng Thái"
            label-for="h-class-status"
            label-cols-md="4"
          >
            <b-row>
              <b-col>
                <b-form-checkbox
                  id="h-class-status"
                  v-model="form.status"
                >Đã Bắt Đầu</b-form-checkbox>
              </b-col>
              <b-col>
                <b-form-checkbox
                  id="h-class-status"
                  v-model="form.status"
                >Đã Kết Thúc</b-form-checkbox>
              </b-col>
            </b-row>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Ngày Bắt Đầu"
            label-for="h-start-date"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="StartDate"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <b-form-datepicker
                id="h-start-date"
                v-model="form.startDate"
                :state="errors.length > 0 ? false:null"
                placeholder="Ngày bắt đầu"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Ngày Kết thúc"
            label-for="h-end-date"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="EndDate"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <b-form-datepicker
                id="h-end-date"
                placeholder="Ngày kết thúc"
                v-model="form.endDate"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Học Phí/Học Viên"
            label-for="h-CostStudent"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="CostStudent"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <b-form-input v-model="form.cost" type="number" class="ft-form-price"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Đánh Giá Kết Thúc Lớp Học"
            label-for="h-reviewStudent"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="reviewStudent"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
                <b-form-textarea max-rows="10" v-model="form.statusReview" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import VueSelect from 'vue-select'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BFormCheckbox, BCardHeader, BCardTitle, BCardBody, BFormTextarea, BFormInput, BFormDatepicker, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import InputMinusPlusVue from '@/@core/components/form/InputMinusPlus.vue'

export default {
  props: {
    form: {
      type: Object,
      required: true,
      default: {
        status: () => {},
      }
    }
  },
  components: {
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    InputMinusPlusVue,
    VueSelect,
    BFormTextarea,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  methods: {
    validationForm() {
      console.log('submit')
    },
  },
  data() {
    return {
      required,
    }
  },
}
</script>
<style>

  .color-input{
    width: 50%
  }
</style>
