<template>
  <app-collapse accordion>
    <app-collapse-item :isVisible="true" headerStyle="background-color: #d2d6de">
      <template slot="header">
        <div class="d-flex align-items-center">
          <b-card-title class="ml-25"><feather-icon
            size="24"
            class="mr-1"
            icon="InfoIcon"
          />
            <span>Thông Tin Lớp Học</span>
          </b-card-title>
        </div>
      </template>
      <b-row>
        <!--  Full name-->
        <b-col md="12">
          <b-form-group
            label="Mã Lớp"
            label-for="h-class-name"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="ClassName"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <b-form-input
                id="h-class-name"
                v-model="form.classCode"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--chi nhánh-->
        <b-col md="12">
          <b-form-group
            label="Chi Nhánh"
            label-for="h-class-branch"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Classbranch"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <vue-select
                id="h-class-branch"
                v-model="form.branch"
                :state="errors.length > 0 ? false:null"
                :options="data.branchOptions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- chương trình học -->
        <b-col md="12">
          <b-form-group
            label="Chương Trình Học"
            label-for="h-ClassStudyProgram"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="ClassStudyProgram"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <b-form-input
                id="h-ClassStudyProgram"
                v-model="form.studyProgram"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- cấp độ -->
        <b-col md="12">
          <b-form-group
            label="Cấp Độ"
            label-for="h-ClassLevel"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="ClassLevel"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <vue-select
                id="h-ClassLevel"
                v-model="form.level"
                :state="errors.length > 0 ? false:null"
                :options="data.LevelOptions"
                placeholder="Chọn..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- thoi khoa bieu-->
        <b-col md="12">
          <b-form-group
            label="Thời Khóa Biểu"
            label-for="h-schedule-date"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="ScheduleDate"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <flat-pickr
                v-model="form.scheduleDate"
                :config="{ mode: 'multiple', enableTime: true,dateFormat: 'Y-m-d H:i'}"
                class="form-control"
                placeholder="Select date"
                :state="errors.length > 0 ? false:null"
                name="dateNtim"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- hoc sinh toi thieu-->
        <b-col md="12">
          <b-form-group
            label="Học Sinh Tối Thiểu"
            label-for="h-manager"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="StudentMin"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <input-minus-plus-vue
                v-model="form.studentMin"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--hoc sinh toi da-->
        <b-col md="12">
          <b-form-group
            label="Học Sinh Tối Đa"
            label-for="h-manager"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="StudentMax"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <input-minus-plus-vue
                v-model="form.studentMax"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- tro giang-->
        <b-col md="12">
          <b-form-group
            label="Trợ Giảng"
            label-for="h-TeactAt"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="TeactAt"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <vue-select
                id="h-TeactAt"
                v-model="form.teacherAT"
                :state="errors.length > 0 ? false:null"
                :options="data.TeacherATOptions"
                placeholder="Chọn..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- giao vien lop-->
        <b-col md="12">
          <b-form-group
            label="Giáo viên lớp"
            label-for="h-teacher"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Teacher"
              :customMessages="{['required']: 'Thông tin bị thiếu'}"
            >
              <vue-select
                id="h-ClassLevel"
                v-model="form.teacher"
                :state="errors.length > 0 ? false:null"
                :options="data.TeacherOptions"
                placeholder="Chọn..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
  </app-collapse-item>
  </app-collapse>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import VueSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCardHeader, BCardTitle, BCardBody, BFormTextarea, BFormInput, BFormDatepicker, BFormGroup, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import InputMinusPlusVue from '@/@core/components/form/InputMinusPlus.vue'

export default {
  props: {
    form: {
      type: Object,
      required: true,
      default: {}
    },
    data: {
      type: Object,
      required: true,
      default: {}
    }
  },
  components: {
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    InputMinusPlusVue,
    VueSelect,
    BFormTextarea,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  methods: {
    validationForm() {
      console.log('submit')
    },
  },
  data() {
    return {
      required,
    }
  },
}
</script>
<style>

  .color-input{
    width: 50%
  }
</style>
