<template>
  <b-card no-body>
    <b-card-header class="ft-card-header-create-user">
      <b-card-title>
        <feather-icon icon="FileIcon" size="24" />{{ id ? 'Sửa Thông Tin Lớp Học' : 'Tạo Thông Tin Lớp Học'}}</b-card-title>
      <b-button variant="success" @click="onSubmit"><feather-icon icon="SaveIcon" />Lưu Lại</b-button>
    </b-card-header>
    <b-card-body>
      <validation-observer ref="createClass">
        <b-row>
          <b-col cols="8">
            <info-class :form="form" :data="data" />
          </b-col>
          <b-col cols="4">
            <status-class :form="form" :data="data"/>
          </b-col>
          <b-col cols="12">
            <schedule-time :form="form.scheduleTime" />
          </b-col>
        </b-row>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BCardHeader, BCardTitle, BCardBody, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import InfoClass from './components/InfoClass.vue'
import StatusClass from './components/StatusClass.vue'
import ScheduleTime from '../../components/scheduleTime.vue'

export default {
  props: {
    id: {
      type: String,
      default: null
    },
  },
  components: {
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    InfoClass,
    StatusClass,
    ScheduleTime
},
  methods: {
    dateDisabled(ymd, date) {
      const day = date.getDate()
      return day < new Date(new Date(this.filterDateFrom).getDate())
    },
    columnFilter(t) {
      this.filterOn = [t]
      // eslint-disable-next-line default-case
      switch (t) {
        case 'course' :
        {
          this.filter = this.filterKhoaHoc
        }
        break;
        case 'class_name' :
        {
          this.filter = this.filterLopHoc
        }
        break;
        case 'teacher' :
        {
          this.filter = this.filterGiaoVien
        }
        break;
        case 'teacher_at' :
        {
          this.filter = this.filterTeacherAt
        }
        break;
      }
    },
    onSubmit() {
      this.$refs.createClass.validate().then((result) => {
        if(result === true) {
          console.log('result createClass', this.form)
        }
      })
    },
  },
  created() {
    if (this.id !== null) {
      this.form = {
        branch: null,
        teacher: null,
        staff_name: null,
        phone_number: null,
        birthday: null,
        type: null,
        gender: null,
        studentMax: 20,
        studentMin: 1,
        teacherAT: null,
        level: null,
        studyProgram: null,
        className: null,
        scheduleDate: null,
        classCode: '123',
        status: null,
        startDate: null,
        endDate: null,
        cost: null,
        statusReview: null,
      }
    }
  },
  mounted() {
    document.title = (this.id ? 'Sửa Thông Tin Lớp Học' : 'Tạo Thông Tin Lớp Học') + ' | ' + this.$route.meta.pageTitle
  },
  data() {
    return {
      dateNtim: null,
      filterKhoaHoc: null,
      filterLopHoc: null,
      filterGiaoVien: null,
      filterTeacherAt: null,
      count: 0,
      filterDateFrom: null,
      filterDateTo: null,
      filter: '',
      filterOn: [],
      rowsItems: [
        {
          id: 1,
          course: 'Khóa 1',
          class_name: '1',
          teacher: 'Quốc DŨng',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 10,
          start_date: 1658925621,
          end_date: 1658925621,
        },
        {
          id: 2,
          course: 'Khóa 2',
          class_name: '2',
          teacher: 'Danh Sinh',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 10,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 3,
          course: 'Khóa 3',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 10,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 10,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 5,
          course: 'Khóa 5',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 10,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 6,
          course: 'Khóa 6',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 10,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 7,
          course: 'Khóa 7',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 10,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
      ],
      fieldsTable: [
        {
          label: 'Khóa Học',
          key: 'course',
        },
        {
          label: 'Tên Lớp',
          key: 'class_name',
        },
        {
          label: 'Giáo viên',
          key: 'teacher',
        },
        {
          label: 'Trợ Giảng',
          key: 'teacher_at',
        },
        {
          label: 'Giá Tiền',
          key: 'price',
        },
        {
          label: 'Học Sinh Đã Vào',
          key: 'student_current',
        },
        {
          label: 'Số Học Sinh Tối Đa',
          key: 'student_max',
        },
        {
          label: 'Ngày Bắt Đầu',
          key: 'start_date',
        },
        {
          label: 'Ngày Kết Thúc',
          key: 'end_date',
        },
        {
          label: '',
          key: 'act',
        },
      ],
      data: {
        branchOptions: [
          { value: null, label: 'Chi Nhánh 1' },
        ],
        LevelOptions: [
          { value: null, label: 'Tuổi' },
        ],
        TeacherATOptions: [
          { value: null, label: 'Trợ Giảng B' },
        ],
        TeacherOptions: [
          { value: null, label: 'Giáo Viên A' },
        ],
      },
      form: {
        branch: null,
        teacher: null,
        staff_name: null,
        phone_number: null,
        birthday: null,
        type: null,
        gender: null,
        studentMax: 20,
        studentMin: 1,
        teacherAT: null,
        level: null,
        studyProgram: null,
        className: null,
        scheduleDate: null,
        classCode: null,
        status: null,
        startDate: null,
        endDate: null,
        cost: null,
        statusReview: null,
        scheduleTime: {
          color: '#ff00ff'
        },
      },
      required,
    }
  },
}
</script>
<style>
  .color-input{
    width: 50%
  }
</style>
